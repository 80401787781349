<template>
    <Skeleton 
        :wrapperClass="wrapperClass"
        ref="skeleton"
        v-if="loading" 
    />
    
    <div v-else :class="wrapperClass">
        <div class="section-title-wrapper">
            <h2 class="section-title" v-text="title"></h2>
            <div class="dosiin_slider-controls">
                <div class="dosiin_slider-pagination dosiin_slider-pagination-highlight swipable-slider_pagination swiper-button-pagination swiper-filter-nav-bar"></div>
            </div>
        </div>

        <div class="dosiin_product-highlight">
            <Swiper 
                :modules="modules"
                :pagination="pagination"
                class="swiper-wrapper"
            >
                <SwiperSlide v-for="(products , index ) in productsSlide" :key="index">
                    <ul class="product-list_first_line grid grid-50">
                        <li class="grid-item">
                            <ProductItem :product="products[0]" />
                        </li>
                        <li class="grid-item" v-if="products[1]">
                            <ProductItem :product="products[1]" />
                        </li>
                    </ul>

                    <ul class="product-list_second_line grid grid-33" v-if="products[2]">
                        <li class="grid-item"><ProductItem :product="products[2]" /></li>
                        <li class="grid-item" v-if="products[3]"><ProductItem :product="products[3]" /></li>
                        <li class="grid-item" v-if="products[4]"><ProductItem :product="products[4]" /></li>
                    </ul>
                </SwiperSlide>  
            </Swiper>
        </div>

        <div v-if="showLink" class="seemore-btn more">
            <router-link class="primary-button button" :to="link" :title="title">
                <span class="primary-link_text">Xem tất cả</span>
                <i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
        </div>
    </div>
</template>
 
<script>
    import { toRefs , ref , watch } from 'vue';
    import ProductItem from '@/components/product/ProductItem';
    import Skeleton from '@/components/skeletons/sliders/ProductVertical';
    import { Pagination } from 'swiper';
    export default {
        components:{
            ProductItem,
            Skeleton
        },
        props:{
            title : {
                type : String, 
                default : ''
            },
            wrapperClass :{
                type : String, 
                default : ''
            },
            products :{
                type : Array,
                require : true,
                default : []
            },
            showLink :{
                type : Boolean, 
                default : false
            },
            link :{
                type : String, 
                default : '/'
            }
        },
      
        setup(props , { emit }) {
            const loading = ref(true);
            const options = {};
            const skeleton = ref(null);
            const productsSlide = ref([]);
            const pagination =  {
                el: '.dosiin_slider-pagination-highlight',
                clickable: true,
                renderBullet: (index, className) => `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
            };

            watch(() => props.products , (products) => {
                if(products.length){
                    
                    
                    var i,j, chunk = 5;
                    for (i = 0,j = products.length; i < j; i += chunk) {
                        productsSlide.value.push(products.slice(i, i + chunk));
                        // do whatever
                    }
                    // paginate array
                    loading.value = false;
                }   
            });

            return {
                loading,
                options,
                skeleton,
                productsSlide,
                ...toRefs(props),
                pagination,
                modules:[Pagination]
            }
        },
    }
</script>