<template>
    <section :class="wrapperClass">
        <div class="section-title-wrapper">
            <Skeletor class="section-title" width="30%" />
            <Skeletor class="dosiin_slider-controls" width="20%"/>
        </div>

        <div class="dosiin_product-highlight">
            <ul class="product-list_first_line grid grid-50">
                <li class="grid-item">
                    <ProductSkeleton />
                </li>
                <li class="grid-item">
                    <ProductSkeleton />
                </li>
            </ul>

            <ul class="product-list_second_line grid grid-33">
                <li class="grid-item">
                    <ProductSkeleton />
                </li>
                <li class="grid-item">
                    <ProductSkeleton />
                </li>
                <li class="grid-item">
                    <ProductSkeleton />
                </li>
            </ul>

            <div class="seemore-btn more">
                <Skeletor width="100%" height="36" pill />
            </div>
        </div>
    </section>
</template>

<script>
    import { toRefs } from 'vue';
    import ProductSkeleton from '@/components/skeletons/product/ProductItem'; 
    export default {
        components:{
            ProductSkeleton
        },
        props:{
            wrapperClass :{
                type: String ,
                default : ''
            }
        },
        setup(props) {
            
            return {
                ...toRefs(props)
            }
        },
    }
</script>