<template>
    <div class="product-main-page-slider dosiin_product-main-page">
        <div class="dosiin_swiper-container swiper-container">
            <div class="swiper-wrapper" id="dosiin_swiper-wraper">
                <div class="swiper-slide swiper-slide-active overflow" style="width:100%;border-radius:8px;">
                    <Skeletor width="100%" :height="height" style="border-radius: 8px;"/>
                </div>
            </div>
            <div class="dosiin_mb-16 dosiin_mt-16 d-flex justify-content-center">
                <Skeletor width="60%" height="20" pill />
            </div>
             
        </div>
    </div>
</template>

<script>
    import { toRefs } from 'vue';
    export default {
        props:['height','width'],
        setup(props) {
            return {
                ...toRefs(props)
            }
        },
    }
</script>