<template>
    <ul :class="wrapperClass">
        <div class="grid-item" v-for="i in 30" :key="i">
            <ProductItem />
        </div>
    </ul>
</template>


<script>
    import ProductItem from '@/components/skeletons/product/ProductItem';

    export default {
        components:{
            ProductItem
        },
        props:{
            wrapperClass:{
                type: String
            }
        }
    }
</script>