import Api from '@/services/Api';

export default {
  fetchProducts(params) {
    return Api().get(`/products`, { params });
  },

  fetchProduct(id) {
    return Api().get(`/products/${id}`);
  },
}