<template>
  <div>
    <ProductLoading v-if="products.length === 0" :wrapperClass="'grid ' + gridClass" />

    <div v-else-if="products.length" class="grid" :class="gridClass">
      <div class="grid-item" v-for="(product, i) in products" :key="i">
        <ProductItem :product="product" :showRating="true" />
      </div>
    </div>

    <EmptyComponent v-else :title="true" />

    <router-link
      v-show="link.length"
      :to="link"
      class="button primary-button"
      style="width: 300px; margin: auto"
    >
      <span class="primary-link_text">Xem thêm sản phẩm</span>
    </router-link>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import ProductItem from "@/components/product/ProductItem";
import ProductLoading from "@/components/skeletons/Products";
import ProductService from "@/services/product";

export default {
  components: {
    ProductLoading,
    ProductItem,
  },
  props: {
    grid: {
      type: Number,
      default: 1,
    },
    query: {
      type: Object,
      default: {},
    },
    link: {
      type: String,

      default: "",
    },
    gridClass: {
      type: String,
      default: "grid-20",
    },
  },
  setup(props) {
    const products = ref([]);

    onMounted(() => {
      getProducts();
    });

    async function getProducts() {
      // change url with params
      const response = await ProductService.fetchProducts(props.query);

      if (response && response.data) {
        products.value = response.data.products;
      }
    }
    return { products };
  },
};
</script>

<style>
.grid.grid-50 .grid-item,
.grid.grid-33 .grid-item,
.grid.grid-11 .grid-item {
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>

<style module>
.subText {
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #b3b3b3;
  text-align: center;
}
</style>
