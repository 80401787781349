import Api from '@/services/Api';

export default {
  fetchBrands(query_string) {
    return Api().get(`/brands${query_string}`);
  },

  fetchBrand(id, params = {}) {
    return Api().get(`/brands/${id}`, { params });
  },

  fetchBrandsSale(params){
    return Api().get(`4.0/BrandsSale`, { params });
  },

  fetchBrandsBanner(params){
    return Api().get(`4.0/BrandsBanner`, { params });
  }
}