<template>
  <section :class="sectionClass">
    <SliderSkeleton :height="height" v-if="banners.length == 0" />
    <div v-else :class="wrapperClass">
      <div class="dosiin_swiper-container" :class="{ 'swiper-container': layout == 4 }">
        <Swiper
          :slidesPerView="options.slidesPerView ? options.slidesPerView : 'auto'"
          :class="[swiperClass, 'unsetOverflow']"
          :modules="modules"
          :loop="options.loop"
          :pagination="options.pagination"
          :navigation="options.navigation"
          :autoplay="options.autoplay"
          :centeredSlides="options.centeredSlides"
          :grabCursor="options.grabCursor"
          :coverflowEffect="options.coverflowEffect"
          :effect="options.effect"
          :breakpoints="options.breakpoints"
        >
          <!-- <SwiperSlide :class="swiperItemClass" style="width: 100%">
            <a href="https://musinsa.dosi-in.com/" v-if="layout == 4">
              <img
                class="item-images-slider"
                height="188"
                lazy="loaded"
                src="https://dosi-in.com/images/assets/musinsa-lp-08-12/logomusinsamb-01.png"
                alt="banner"
                width="100%"
                style="display: block"
              />
            </a>
            <a href="https://musinsa.dosi-in.com/" v-else>
              <img
                src="https://dosi-in.com/images/assets/musinsa-lp-08-12/logomusinsapc-01.png"
                alt="banner"
                width="100%"
                style="display: block"
              />
            </a>
          </SwiperSlide> -->
          <SwiperSlide
            :class="swiperItemClass"
            style="width: 100%"
            v-for="(banner, index) in banners"
            :key="index"
          >
            <a
              :href="banner.url ? banner.url : '#'"
              :class="swiperItemLinkClass"
              v-if="templateBlock"
            >
              <img
                v-lazy="{
                  src: _bannerImage(banner.main_pair),
                  loading: _bannerDefault,
                  error: _bannerDefault,
                }"
                :class="swiperItemImageClass"
                :height="height"
              />
            </a>

            <template v-else>
              <router-link
                v-if="banner.banner_shop"
                :to="banner.seo_name ? _brandUrl(banner.seo_name) : '#'"
                :title="banner.company"
                :class="swiperItemLinkClass"
              >
                <img
                  v-lazy="{
                    src: _brandBannerCrop(
                      banner.banner_shop[Object.keys(banner.banner_shop)[0]]
                    ),
                    loading: _bannerDefault,
                    error: _bannerDefault,
                  }"
                  :class="swiperItemImageClass"
                  :height="height"
                />
              </router-link>

              <router-link
                v-else
                :to="banner.company_data.seo_name ? banner.company_data.seo_name : '#'"
                :title="banner.company"
                :class="swiperItemLinkClass"
              >
                <img
                  v-if="layout == 4"
                  v-lazy="{
                    src: _brandBanner(banner.company_data.banners_main),
                    loading: _bannerDefault,
                    error: _bannerDefault,
                  }"
                  :class="swiperItemImageClass"
                  :height="height"
                />
                <img
                  v-else
                  v-lazy="{
                    src: _brandBannerCrop(banner.company_data.banners_main),
                    loading: _bannerDefault,
                    error: _bannerDefault,
                  }"
                  :class="swiperItemImageClass"
                  :height="height"
                />
              </router-link>
            </template>
          </SwiperSlide>

          <div v-if="options.pagination" class="swiper-pagination"></div>
          <div
            v-if="options.navigation"
            class="swiper-button-next dosiin_swiper-button"
          ></div>
          <div
            v-if="options.navigation"
            class="swiper-button-prev dosiin_swiper-button"
          ></div>
          <div
            v-if="options.navigation"
            class="background-button-slide background-button-next"
          ></div>
          <div
            v-if="options.navigation"
            class="background-button-slide background-button-prev"
          ></div>
        </Swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { toRefs } from "vue";
import SliderSkeleton from "@/components/skeletons/sliders/MainSliderMB";
import { Autoplay, Navigation, Pagination, EffectCoverflow } from "swiper";
export default {
  components: {
    SliderSkeleton,
  },
  props: {
    banners: {
      type: Array,
      require: true,
      default: [],
    },
    options: {
      type: Object,
      require: true,
    },
    sectionClass: {
      type: String,
      default: "",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    swiperClass: {
      type: String,
      default: "",
    },
    swiperItemClass: {
      type: String,
      default: "",
    },
    swiperItemLinkClass: {
      type: String,
      default: "",
    },
    swiperItemImageClass: {
      type: String,
      default: "",
    },
    navigation: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 200,
    },
    templateBlock: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { banners } = toRefs(props);

    return {
      // loading,
      banners,
      modules: [Autoplay, Navigation, Pagination, EffectCoverflow],
    };
  },
};
</script>

<style>
.unsetOverflow {
  overflow: unset !important;
}
</style>
